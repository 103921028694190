import { combineReducers } from 'redux';
import {
  addressesReducer as addresses,
  announcementReducer,
  availabilitiesReducer as availabilities,
  centersTreeReducer as centersTree,
  errorReducer as errors,
  HEALTH_CHECK_FRONT_PATIENT,
  HEALTH_CHECK_FRONT_PATIENT_ENCRYPTION,
  FRONT_PATIENT_ADDRESS_SERVER,
  FRONT_PATIENT_MAP_SERVER,
  loadingReducer as loading,
  reducersGenerationHelper,
  reducersGenerator,
  suggestionsReducer as suggestions,
  websocketReducer as websocket,
} from '@docavenue/core';
import { HYDRATE } from 'next-redux-wrapper';
import temporaryAppointments from './temporaryAppointments';
import snackbar, { QUEUE_NAME as SNACKBAR_QUEUE_NAME } from './snackbar';
import geoip from './geoip';
import ui from './ui';
import serverConfiguration from './serverConfiguration';
import documents from './documents';

const reducerNames = reducersGenerationHelper(
  [
    'authentication',
    'refresh',
    'videoSessions',
    'appointments',
    'specialities',
    'localities',
    'hcd',
    'cards',
    'appointmentSpecialities',
    'consultationReasons',
    'consultationReasonsByName',
    'practitioners',
    'instructions',
    'profiles',
    'registrations',
    'passwordChanges',
    'phoneRequests',
    'careTeam',
    'documents',
    'tlcSpecialities',
    'pharmacies',
    'centers',
    'limitationPatient',
    'anonymousSignatures',
    'anonymousAppointments',
    'relatedCities',
    'waitingAttempts',
    'favoritePractitioners',
    'articles',
    'emailConfirmations',
    'feedbackTlc',
    'brandings',
    'insurance',
  ].map(name => ({
    name,
  })),
);

const tlcCardsReducer = reducersGenerator({
  name: 'tlcCards',
  extendedInitialState: {
    isSettled: false,
  },
  extendReducer: {
    GET_LIST_SUCCESS: state => ({
      ...state,
      isSettled: true,
    }),
    GET_LIST_ERROR: state => ({ ...state, isSettled: true }),
  },
});

const paymentsIntents = reducersGenerator({
  name: 'paymentsIntents',
  surchargeReducer: () => ({
    CREATE_SUCCESS: (state, action) => ({ ...state, items: [action.payload] }),
  }),
});

const suggestionsReducer = (state, action) => {
  if (action.type === 'EMPTY_QUERY') {
    return { ...state, query: '' };
  }
  return suggestions(state, action);
};

// TODO(nampnq): Add flow type action instead object of combineReducers<reducers, Action>
const appReducer = {
  ...reducerNames,
  suggestions: suggestionsReducer,
  addresses,
  availabilities,
  temporaryAppointments,
  websocket,
  errors,
  loading,
  centersTree,
  tlcCards: tlcCardsReducer,
  paymentsIntents,
  documents,
  [SNACKBAR_QUEUE_NAME]: snackbar,
  geoip,
  serverConfiguration,
  ui,
  announcements: announcementReducer([
    HEALTH_CHECK_FRONT_PATIENT,
    HEALTH_CHECK_FRONT_PATIENT_ENCRYPTION,
    FRONT_PATIENT_ADDRESS_SERVER,
    FRONT_PATIENT_MAP_SERVER,
  ]),
};

let _dynamicReducers = {};
let _pendingRemoveState: string[] = [];

export const addReducers = reducers => {
  _dynamicReducers = { ..._dynamicReducers, ...reducers };
};

export const removeReducers = reducers => {
  _pendingRemoveState = [..._pendingRemoveState, ...Object.keys(reducers)];
  _dynamicReducers = Object.keys(_dynamicReducers)
    .filter(key => !Object.keys(reducers).includes(key))
    .reduce((newObj, key) => {
      // eslint-disable-next-line no-param-reassign
      newObj[key] = _dynamicReducers[key];
      return newObj;
    }, {});
};

const objectWithoutKey = (object, key) => {
  const { [key]: deletedKey, ...rest } = object;
  return rest;
};

const rootReducer = (state, action) => {
  if (action.type === HYDRATE) {
    /**
     * 1. First hydration, the server returns a redux store with all the updated states
     * 2. Page navigation, hydration returns a NEW store (updated with PAGE getServerSideProps actions)
     *
     * Ex: / => navigation to => /[speciality]/[locality]
     * - state.specialities, state.address...etc are filled in getServerSideProps
     * - state.authentication will have its initial value !
     *
     * How to avoid this
     *
     * 1. We apply the changes from the server (...action.payload)
     * 2. For specific states, we will override server values with client ones
     *
     * Ex: authentication = state.authentication
     *
     * Be careful when adding a state: it can't be updated from getServerSideProps anymore.
     * Only from client side
     *  */
    return {
      ...state,
      ...action.payload,
      authentication: state.authentication,
      pharmacies: state.pharmacies,
      brandings: state.brandings,
      websocket: state.websocket,
      serverConfiguration: state.serverConfiguration,
      announcements: state.announcements,
    };
  }

  let newState = { ...state };
  const { resource, type } = action;

  if (resource === 'logout' && type === 'CREATE_SUCCESS') {
    // Reset store except pharmacy info
    newState = {
      pharmacies: { ...state?.pharmacies },
      brandings: { ...state?.brandings },
      websocket: { ...state?.websocket },
      serverConfiguration: { ...state?.serverConfiguration },
      announcements: { ...state?.announcements },
    };
  }
  if (_pendingRemoveState.length > 0) {
    for (const key of _pendingRemoveState) {
      newState = objectWithoutKey(state, key);
    }
    _pendingRemoveState = [];
  }
  // TODO(nampnq): Call replaceReducer instead
  // TODO: type ts reducergenerator v1
  // @ts-ignore
  return combineReducers<any, any>({ ...appReducer, ..._dynamicReducers })(
    newState,
    action,
  );
};
export default rootReducer;
