import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { AddressType, addressesActions, asyncActions } from '@docavenue/core';
import { Dispatch } from 'redux';

type UseAddressesOptions = {
  onSuccess?: (data: AddressType[] | null) => void;
  onError?: (err: unknown) => void;
};

export const queryKey = (value?: string) => ['search_address', value];
export const getAddresses = (dispatch: Dispatch, value: string) =>
  asyncActions<AddressType[]>(
    dispatch,
    addressesActions.search(
      value,
      {
        type: ['region+department', 'municipality', ''],
      },
      { scope: 'pat-public' },
    ),
  );

const useAddresses = (
  value: string,
  { onSuccess, onError }: UseAddressesOptions = {
    onSuccess: undefined,
    onError: undefined,
  },
) => {
  const dispatch = useDispatch();

  return useQuery({
    queryKey: queryKey(value),
    // Do not remove "signal" from the parameters
    // It is needed by react-query to consider the query "cancelable"
    // https://github.com/TanStack/query/pull/2782#issuecomment-951454501
    // We don't need to pass the signal in getAddresses because it's already handled in saga
    // packages/core/src/sagas/sagas.ts
    // FYI: throttle set here: packages/core/src/sagas/crud.ts
    // eslint-disable-next-line unused-imports/no-unused-vars-ts
    queryFn: async ({ signal }) => {
      return value ? getAddresses(dispatch, value) : null;
    },
    enabled: !!value,
    cacheTime: 0,
    onSuccess,
    onError,
  });
};

export default useAddresses;
