import { NextApiRequest, NextApiResponse } from 'next';

const config = {
  API_ENDPOINT: process.env.API_ENDPOINT,
  SERVER_API_ENDPOINT: process.env.SERVER_API_ENDPOINT,
  AUTH_ENDPOINT: process.env.AUTH_ENDPOINT,
  ADDRESS_ENDPOINT: process.env.ADDRESS_ENDPOINT,
  ALGOLIA_ENDPOINT: process.env.ALGOLIA_ENDPOINT,
  WEBSOCKET_URI: process.env.WEBSOCKET_URI,
  TOKBOX_API_KEY: process.env.TOKBOX_API_KEY,
  MAPBOX_TOKEN: process.env.MAPBOX_TOKEN,
  MOBILE_BREAKPOINT_WIDTH: process.env.MOBILE_BREAKPOINT_WIDTH,
  STRIPE_API_KEY: process.env.STRIPE_API_KEY,
  NO_INDEX: process.env.NO_INDEX,
  SENTRY_DSN: process.env.SENTRY_DSN,
  SENTRY_RELEASE: process.env.SENTRY_RELEASE,
  GTM_ID: process.env.GTM_ID,
  LINKED_ID_TAG_ID: process.env.LINKED_ID_TAG_ID,
  CHATBOT_API_KEY: process.env.CHATBOT_API_KEY,
  DEBUG: process.env.DEBUG,
  MAP_TYPE: process.env.MAP_TYPE,
  NEXT_PUBLIC_RELEASE_VERSION: process.env.NEXT_PUBLIC_RELEASE_VERSION,
  BUILD_NUMBER: process.env.BUILD_NUMBER,
  BCB_ENDPOINT: process.env.BCB_ENDPOINT,
  SERVER_BCB_ENDPOINT: process.env.SERVER_BCB_ENDPOINT,
  ENVK8S: process.env.ENVK8S,
  TEST_ENV: process.env.TEST_ENV,
  NEXT_PUBLIC_PAT_MAIIA_NEWS_BASEURL:
    process.env.NEXT_PUBLIC_PAT_MAIIA_NEWS_BASEURL,
  DISABLE_ENCRYPTION: process.env.DISABLE_ENCRYPTION,
  ENCRYPTION: process.env.ENCRYPTION,
  TLC_AMC_CENTER_ID: process.env.TLC_AMC_CENTER_ID,
  SERVER_SSO_CLIENT_MAPPER_ENDPOINT:
    process.env.SERVER_SSO_CLIENT_MAPPER_ENDPOINT,
  HCAPTCHA_SITEKEY: process.env.HCAPTCHA_SITEKEY,
  SCREEB_WEBSITE_ID: process.env.SCREEB_WEBSITE_ID,
  SCREEB_ENABLED: process.env.SCREEB_ENABLED,
  NEXT_PUBLIC_MAIIA_NEWS_BASEURL: process.env.NEXT_PUBLIC_MAIIA_NEWS_BASEURL,
  HOSTNAME: process.env.HOSTNAME,
  NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
} as const;

// properly access public runtime configuration on both client-side and server-side
export const getRuntimeConfig = (name: keyof typeof config) =>
  typeof window === 'undefined'
    ? config[name]
    : ((window as any).runtimeConfig as typeof config)[name];

export default function handler(_req: NextApiRequest, res: NextApiResponse) {
  res.setHeader('Content-Type', 'application/javascript');
  res.status(200).send(`window.runtimeConfig = ${JSON.stringify(config)}`);
}
