import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { useCurrentUser } from '@docavenue/components';
import { useSelector } from 'react-redux';
import { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import {
  IconCalendarDate,
  IconCross,
  IconDocuments,
  IconDrug,
  IconIISquareFilled,
  IconPersonRoundFilled,
  IconPractitioner,
  IconVideoCamera,
  VectorLogoMaiia,
} from '@maiia/design-system';
import { Button } from '@mui/material';
import { Typography } from '@kiiwi/ui';
import Link from '../../atoms/Link/Link';

import { useTranslation } from '@/src/i18n';
import { analyticsEvent } from '@/src/analytic_tag_manager';
import InsuranceNavLinks from './InsuranceNavLinks';
import useBranding from '@/src/hooks/branding/useBranding';
import { APP_ROUTES } from '@/src/constants';
import { screebEventTrack } from '@/src/screeb';
import FontAwesomeIcon from '@/components/atoms/FontawesomeIcon/FontawesomeIcon';

type IconLinkProps = {
  href: LinkProps['href'];
  className?: string;
  iconComponent: JSX.Element;
  label: string;
  gtmAction: string;
};

const MenuContext = React.createContext<{
  toggleDrawer: React.ReactEventHandler;
}>({
  toggleDrawer: () => {},
});

const IconLink = (props: IconLinkProps) => {
  const { href, className, iconComponent, label, gtmAction } = props;
  const router = useRouter();
  const isSelected = router.pathname === href;
  const menu = React.useContext(MenuContext);

  return (
    <Link href={href} passHref>
      <a
        className={clsx(className, isSelected && 'selected')}
        onClick={e => {
          menu.toggleDrawer(e);

          analyticsEvent({
            category: 'NavMenu',
            action: gtmAction,
          });
          if (gtmAction === 'BCB') {
            screebEventTrack('BCB CTA');
          }
        }}
        // TODO : Remove data-cy when the global refactoring will be done
        data-cy={`navigation-${label.toLowerCase().replace(/\s/g, '-')}`}
        data-testid={`navigation-${label.toLowerCase().replace(/\s/g, '-')}`}
      >
        {iconComponent}
        <p>{label}</p>
      </a>
    </Link>
  );
};

const Menu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const currentUser = useCurrentUser();
  const toggleDrawer = React.useCallback(() => setIsOpen(!isOpen), [isOpen]);
  const { t } = useTranslation();
  const { data: branding } = useBranding();
  const uiSettings = useSelector((state: any) => state.ui);

  return (
    <MenuContext.Provider value={{ toggleDrawer }}>
      <button
        type="button"
        className={clsx(
          uiSettings?.showNewDocumentNotification &&
            'navigation-burger--notification',
        )}
        title="Menu"
        onClick={toggleDrawer}
        data-cy="navigation-burger"
      >
        <div className="flex items-center gap-2">
          <FontAwesomeIcon
            name="bars:regular"
            className="size-4 text-neutral-inversed"
          />
          <Typography className="text-xl font-semibold text-neutral-inversed">
            {t('menu')}
          </Typography>
        </div>
      </button>
      <Drawer open={isOpen} onClose={toggleDrawer}>
        <nav className="nav">
          <div
            className="nav__close"
            onClick={toggleDrawer}
            data-cy="navigation-close"
          >
            <IconCross />
          </div>
          <div className="nav__header">
            <Link href={APP_ROUTES.HOME}>
              {branding?.mainPicture?.s3Id ? (
                <img
                  src={`/files/${branding?.mainPicture?.s3Id}`}
                  className="brand brandname--pharmacy"
                  alt="logo"
                  data-cy="navigation-logo"
                />
              ) : (
                <VectorLogoMaiia data-cy="navigation-logo" />
              )}
            </Link>
          </div>
          <div className="nav__body">
            {isEmpty(currentUser) && (
              <>
                <a href="https://suite.maiia.com/" className="nav-link">
                  <IconPractitioner />
                  <span>{t('practitioner-menu')}</span>
                </a>
                <hr />
              </>
            )}

            <IconLink
              href={APP_ROUTES.HOME}
              className="nav-link"
              iconComponent={<IconIISquareFilled />}
              label={t('home-menu')}
              gtmAction="Home"
            />

            <IconLink
              href={APP_ROUTES.ACCOUNT.PROFILE}
              className="nav-link"
              iconComponent={<IconPersonRoundFilled />}
              label={t('account-settings')}
              gtmAction="NavMenu_MyAccount"
            />

            <IconLink
              href={APP_ROUTES.TLC.NO_RDV_SEARCH_SLUG}
              className="nav-link"
              iconComponent={<IconVideoCamera />}
              label={t('tlc-menu')}
              gtmAction="Teleconsultation"
            />

            <IconLink
              href={APP_ROUTES.DOCUMENTS}
              className={clsx(
                'nav-link',
                uiSettings?.showNewDocumentNotification &&
                  'nav-link--notification',
              )}
              iconComponent={<IconDocuments />}
              label={t('documents-menu')}
              gtmAction="Documents"
            />

            <IconLink
              href={APP_ROUTES.RDV}
              className="nav-link"
              iconComponent={<IconCalendarDate />}
              label={t('appointment')}
              gtmAction="Appointments"
            />

            <IconLink
              href={APP_ROUTES.MEDICAMENTS.BASE_URL}
              className="nav-link nav-link-bcb"
              iconComponent={<IconDrug />}
              label={t('drugs_list_title')}
              gtmAction="BCB"
            />

            <InsuranceNavLinks toggleDrawer={toggleDrawer} />
          </div>

          <div className="nav__footer">
            <Link href="https://maiia-guide.zendesk.com/hc/fr" passHref>
              <Button
                color="primary"
                variant="outlined"
                onClick={() =>
                  analyticsEvent({
                    category: 'NavMenu',
                    action: 'Help',
                  })
                }
                data-testid="navigation-aide-contact"
              >
                {t('help-menu')}
              </Button>
            </Link>
          </div>
        </nav>
      </Drawer>
    </MenuContext.Provider>
  );
};

export default Menu;
