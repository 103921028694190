import * as React from 'react';
import { useCurrentUser } from '@docavenue/components';
import { Button, Typography } from '@kiiwi/ui';
import { useTranslation } from '@/src/i18n';
import { APP_ROUTES } from '@/src/constants';
import Link from '@/components/atoms/Link/Link';
import useResponsive from '@/src/hooks/useResponsive';

const Authenticated = () => {
  const { t } = useTranslation();
  const { isTablet } = useResponsive();

  const patient = useCurrentUser()?.userPatientInformation?.userPatientProfiles?.find(
    p => p.isDefault,
  );

  return (
    <Link href={APP_ROUTES.ACCOUNT.PROFILE} passHref>
      <a data-cy="header-account">
        <Button
          size="sm"
          variant="ghost"
          className="px-0 py-0 hover:bg-transparent active:scale-100 active:bg-transparent"
        >
          <span className=" flex size-8 items-center justify-center rounded-full bg-[#FEC3D7] text-[#AA0E42]">
            {patient?.firstName?.charAt(0)?.toUpperCase() ?? '!'}
          </span>
          {!isTablet && (
            <Typography className="ml-2 font-semibold text-neutral-inversed">
              <span className="capitalize">{patient?.firstName ?? ''}</span>{' '}
              <span className="capitalize">
                {patient?.lastName ?? t('finalize_my_registration')}
              </span>
            </Typography>
          )}
        </Button>
      </a>
    </Link>
  );
};

export default Authenticated;
