import React, { useState } from 'react';
import { asyncActions } from '@docavenue/core';
import { useCurrentUser } from '@docavenue/components';
import { useDispatch, useSelector } from 'react-redux';
import { usersActions, authActions } from '@/src/actions';
import { CustomPage } from '@/types/page.types';
import NoPageFlicker from '../NoPageFlicker/NoPageFlicker';
import { PatientUser } from './types';
import { isCompleted, redirectWithNextParam } from './functions';
import PrivacyPolicyPopup from '@/components/organisms/PrivacyPolicyPopup/PrivacyPolicyPopup';
import { isEncryptionServiceStatusFetchedSelector } from '@/src/selectors';

type Props = Pick<
  CustomPage,
  'authenticated' | 'suppressFirstRenderFlicker' | 'redirectAuthenticatedTo'
>;

const WithAuth = ({
  children,
  authenticated: authConfig,
  suppressFirstRenderFlicker,
  redirectAuthenticatedTo,
}: React.PropsWithChildren<Props>) => {
  const dispatch = useDispatch();
  const [isInitialized, setIsInitialized] = useState(false);
  const requireAuthenticated = typeof authConfig !== 'undefined';
  const noPageFlicker = suppressFirstRenderFlicker || requireAuthenticated;
  const currentUser = useCurrentUser<PatientUser>();
  const isEncryptionServiceStatusFetched = useSelector(
    isEncryptionServiceStatusFetchedSelector,
  );

  React.useEffect(() => {
    if (!isEncryptionServiceStatusFetched) return;
    const fetchCurrentUser = async (): Promise<PatientUser | undefined> => {
      if (!localStorage.getItem('tokenPat')) return;
      if (currentUser) {
        return currentUser;
      }
      try {
        const user = await asyncActions(dispatch, usersActions.getOne('me'));
        dispatch(authActions.setItem(user));
        return user;
      } catch (e) {
        // User not logined
        // Dispatch logout action to clean token
        dispatch({ type: 'CREATE_SUCCESS', resource: 'logout' });
      }
    };
    const handleRedirect = async (user: PatientUser | undefined) => {
      // redirectAuthenticatedTo is used in case /login, /register
      if (
        typeof redirectAuthenticatedTo !== 'undefined' &&
        typeof window !== 'undefined' &&
        user
      ) {
        const isLoggedIn = isCompleted(user, { loggedin: true });
        if (isLoggedIn) return window.location.replace(redirectAuthenticatedTo);
      }
      if (!requireAuthenticated) return;
      if (!user) return redirectWithNextParam('/login');
      const verifyRoute = isCompleted(user, authConfig);
      if (typeof verifyRoute === 'string') redirectWithNextParam(verifyRoute);
    };
    fetchCurrentUser().then(res => {
      // We wait until we fetched the current user because on some pages
      //  (like /amc-link), we need to be sure on the first render if the user
      //  is loggedin or not
      setIsInitialized(true);

      return handleRedirect(res);
    });
  }, [authConfig, redirectAuthenticatedTo, isEncryptionServiceStatusFetched]);

  const renderChildren = () => {
    const childrenWithPrivacyPopup = (
      <PrivacyPolicyPopup>{children}</PrivacyPolicyPopup>
    );

    if (!requireAuthenticated) return childrenWithPrivacyPopup;
    // While we haven't finished fetching the user, we don't render the children
    if (!isInitialized) return null;

    const isAuthenticated = currentUser && isCompleted(currentUser, authConfig);

    return isAuthenticated === true ? childrenWithPrivacyPopup : null;
  };

  return (
    <>
      {noPageFlicker && <NoPageFlicker />}
      {renderChildren()}
    </>
  );
};

export default WithAuth;
