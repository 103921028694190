import React from 'react';
import { NearMeRounded, HomeRounded } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { styled, css, lighten } from '@mui/material/styles';
import { useCurrentUser } from '@docavenue/components';
import { useDispatch, useSelector } from 'react-redux';
import {
  addressesActions,
  AddressType,
  asyncActions,
  transformAddress,
  TransformedAddressType,
} from '@docavenue/core';
import { useRouter } from 'next/router';
import { useMutation } from '@tanstack/react-query';
import clsx from 'clsx';
import { useTranslation } from '@/src/i18n';
import { ANALYTICS_ADDRESS_TYPES, APP_ROUTES } from '@/src/constants';
import useTransformedAddress from '@/src/hooks/useAddresses/useTransformedAddress';

type Props = {
  onSelectItem?: (
    transformedAddressType: TransformedAddressType & { analyticsType?: string },
  ) => void;
  onSearch?: (value: string) => void;
};

const StyledButton = styled(Button)(
  ({ theme }) => css`
    padding: ${theme.spacing(1.5, 2)};
    font-size: ${theme.typography.body1.fontSize};
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    text-overflow: ellipsis;
    border-radius: 0;
    span {
      display: inline;
    }
    &:hover {
      background-color: ${lighten(theme.palette.primary.main, 0.9)};
    }

    @keyframes blink {
      0% {
        opacity: 100%;
      }
      50% {
        opacity: 30%;
      }
      100% {
        opacity: 100%;
      }
    }

    &.loading {
      animation: blink 2s ease-in-out infinite;
    }
  `,
);

// Can be used with a <Loader /> at the end of the label
// const StyledLoadingButton = styled(StyledButton)(
//   ({ theme }) => css`
//     display: flex;
//     flex-direction: row;
//     justify-content: start;
//     border-radius: 0;
//     &:hover {
//       background-color: ${lighten(theme.palette.primary.main, 0.9)};
//     }
//   `,
// );

const UserLocationButtons = ({ onSelectItem, onSearch }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profileFullAddress = useCurrentUser()?.userPatientInformation
    ?.userPatientProfiles?.[0].address?.fullAddress;

  const {
    data: profileAddressTransformed,
    isFetching: isFetchingTransformedAddress,
  } = useTransformedAddress(profileFullAddress);

  const geoipLocation = useSelector(
    (state: any) => state?.geoip?.location ?? {},
  );
  const router = useRouter();

  const reverseAddressViaNavigator = React.useCallback(async () => {
    if (typeof navigator === 'undefined' || !navigator?.geolocation) {
      return Promise.reject();
    }
    try {
      const {
        coords: { longitude, latitude },
      } = await new Promise(
        (resolve: PositionCallback, reject: PositionErrorCallback) =>
          navigator.geolocation.getCurrentPosition(resolve, reject),
      );
      const data = (await asyncActions(
        dispatch,
        addressesActions.reverse(longitude, latitude),
      )) as AddressType[];
      if (data.length === 0) {
        return Promise.reject();
      }
      return Promise.resolve(data);
    } catch (error) {
      // eslint-disable-next-line
      console.warn(error);
      return Promise.reject();
    }
  }, []);

  const reverseAddressViaGeoip = React.useCallback(async () => {
    const { longitude, latitude } = geoipLocation;
    if (!longitude || !latitude) return Promise.reject();
    const data = (await asyncActions(
      dispatch,
      addressesActions.reverse(longitude, latitude),
    )) as AddressType[];
    if (data.length === 0) {
      return Promise.reject();
    }
    return Promise.resolve(data);
  }, [geoipLocation?.longitude, geoipLocation?.latitude]);

  // FYI: on responsive mode, the click on "my location" close the suggestions
  // it unmounts this components so we can't use "useQuery" because callbacks will not be fired
  // https://github.com/TanStack/query/discussions/5133
  const {
    isLoading: isLoadingGeolocation,
    mutate: updateGeolocation,
  } = useMutation({
    mutationFn: async () => {
      return (
        reverseAddressViaNavigator()
          // eslint-disable-next-line no-return-await
          .catch(async () => await reverseAddressViaGeoip())
          .catch(() => null)
      );
    },
    onSuccess: data => {
      if (data) {
        const transformedAddress = {
          ...transformAddress(data[0]),
          analyticsType: ANALYTICS_ADDRESS_TYPES.LOCATION,
        };

        if (onSelectItem) {
          onSelectItem(transformedAddress);
        } else {
          onSearch?.(transformedAddress.fullAddress);
        }
      }
    },
  });

  const onClickReverse = () => {
    updateGeolocation();
  };

  const userAddressHandler = () => {
    if (profileAddressTransformed && onSelectItem) {
      onSelectItem({
        ...profileAddressTransformed,
        analyticsType: ANALYTICS_ADDRESS_TYPES.ADDRESS,
      });
    } else if (profileFullAddress && onSearch) {
      onSearch(profileFullAddress);
    } else {
      router.push({
        pathname: APP_ROUTES.REGISTER,
      });
    }
  };

  return (
    <Box className="border border-neutral">
      <StyledButton
        startIcon={<NearMeRounded className="btn-location-icon" />}
        onClick={onClickReverse}
        fullWidth
        className={clsx(isLoadingGeolocation && 'loading')}
      >
        {t('current-location')}
      </StyledButton>
      <StyledButton
        startIcon={<HomeRounded className="btn-location-icon" />}
        onClick={userAddressHandler}
        fullWidth
        className={clsx(isFetchingTransformedAddress && 'loading')}
      >
        {t('my-address')}
        {profileFullAddress ? ` : ${profileFullAddress}` : ''}
      </StyledButton>
    </Box>
  );
};

export default UserLocationButtons;
